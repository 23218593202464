<template>
    <div class="app ">
        <div class="justify-content-center">
            <b-card no-body class="login-box">
                <h2 class="login-box__header">Did you forget your password? No problem!</h2>
                <p class="login-box__subheader">
                    Enter the email associated with your account and we'll send you a verification email
                </p>

                <b-card-body>
                    <wit-tabless-form
                        :formId="formId"
                        :inputs="inputs"
                        ref="tablessForm"
                        :valid="!$v.$invalid"
                        finish-label="Submit"
                        :disabled="emailSent"
                        @input="onFormInput"
                        @finish="forgotPassword"
                    >
                        <template #feedback>
                            <Feedback :state="forgotError.state" :invalid="forgotError.message"></Feedback>
                            <p v-if="emailSent" class="text-success">Verification email sent, check your mailbox</p>
                        </template>
                    </wit-tabless-form>
                    <p class="login-box__footer">
                        Already have an account? <router-link to="/auth/login">Log in</router-link>
                    </p>
                </b-card-body>
            </b-card>
        </div>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'

import Feedback from '@/components/Feedback'
import Loading from '@/components/loading.vue'
import WitTablessForm from '@/components/WitTablessForm.vue'

import {formMixin} from '@/mixins/formMixin'

export default {
    components: {
        Feedback,
        Loading,
        WitTablessForm,
    },
    data() {
        return {
            forgotPasswordForm: {
                email: '',
            },
            emailSent: false,
            forgotError: {
                state: true,
                message: '',
            },
            formId: 'forgot-password-form',
        }
    },
    mixins: [formMixin, validationMixin],
    validations: {
        forgotPasswordForm: {
            email: {
                required,
                email,
            },
        },
    },
    computed: {
        inputs() {
            return [
                {
                    name: 'email',
                    value: this.$v.forgotPasswordForm.email.$model,
                    model: this.$v.forgotPasswordForm.email,
                    type: 'text',
                    inputLabel: 'Email',
                    placeholder: 'sample@email.com',
                    invalid: 'This field is required',
                },
            ]
        },
    },
    methods: {
        async forgotPassword() {
            this.$store.commit('loading/PROCESSING', `Processing...`)

            const data = {email: this.forgotPasswordForm.email}
            try {
                await this.axios.post(`${process.env.VUE_APP_NODE_API_HOST}/password/forgot`, data)
                this.emailSent = true
                this.forgotError.state = true
            } catch (exception) {
                this.forgotError.state = false
                this.forgotError.message = exception.response.data.additionalInfo
                this.$errorHandler.report(exception, 'Could not reset password')
            }

            this.$store.commit('loading/PROCESSED')
        },
    },
}
</script>
